import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

declare const cloudMedia: any;

@Component({
  selector: 'app-video-post',
  templateUrl: './video-post.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPostComponent implements OnInit {
  cloudMedia: any;

  ngOnInit(): void {
    this.cloudMedia = cloudMedia;
  }

}
