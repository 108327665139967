import { Observable } from "rxjs";

export function getImageDimensions(imageUrl): Observable<{height: number, width: number}> {
  return new Observable((observer) => {
    const img = new Image();
    img.onload = () => {
      observer.next({height: img.height, width: img.width});
      observer.complete();
    };
    img.src = imageUrl;
  });
}
