import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ActivityType } from '@pepconnect/shared/feed/enums/activity-type.enum';
import { FeedService } from '@pepconnect/shared/feed/services/feed.service';

@Component({
  selector: 'app-post-container',
  templateUrl: './post-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostContainerComponent implements OnInit {
  @Input() type: string
  iconClass: string;

  constructor(private feedService: FeedService) { }

  ngOnInit(): void {
    const activityType: ActivityType = ActivityType[this.type];
    
    if (activityType)
      this.iconClass = `${this.feedService.getActivityIconClass(activityType)} icon-color`;
  }
}
