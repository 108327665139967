import { Link } from "@pepconnect/models/general/link.model";
import { VJSSources } from "@pepconnect/models/media/vjs-source.model";
import { User } from "@pepconnect/models/user/user.model";

export interface UserPost {
  userPostID: string;
  user: User;
  message: string;
  createDate: string;
  modifiedDate: string;
  isFlagged: boolean;
  isDeleted: boolean;
  actions: Link[];
  comments: UserPost[];
  teamID: string;
  allowComments: boolean;
  mediaUrl?: string;
  mediaSize?: number;
  mediaType?: string;
  hasVideo?: boolean;
  hasImage?: boolean;
  videoSources?: VJSSources;
  formattedImage?: string;
}

export function defaultUserPostFactory(): UserPost {
  return {
    userPostID: '',
    user: null,
    message: '',
    createDate: '',
    modifiedDate: '',
    isFlagged: false,
    isDeleted: false,
    actions: [],
    comments: [],
    teamID: '',
    allowComments: false,
  } as UserPost;
}
