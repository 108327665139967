<div class="display-flex margin-top-1">
    <div class="justify-content-center">
        <img class="event-image" [src]="backgroundImage" />
    </div>
    <div class="justify-content-left">
        <h6 class="margin-top-0" [innerHtml]="offering?.name"></h6>
        <div class="font-size-xsmall">
            {{offering?.startDate | date: 'mediumDate'}} {{offering?.startDate | date: 'shortTime'}} {{offering?.startDateTimezone?.abbreviation}}
        </div>
        <div *ngIf="offering?.isExternal" class="margin-top-0">
            <span [innerText]="getExternalInstructors()" class="font-size-xsmall"></span>
        </div>
        <div *ngIf="!offering?.isExternal" class="margin-top-0">
            <span [innerText]="getInstructors()" class="font-size-xsmall"></span>
        </div>
    </div>
</div>

