/**
   * Need string enums to compare again string values coming back from api
   *
   * Note: Feeds are going away soon, kept these in upper case to save time
   * 
   */
export enum ActivityType {
  FEED_TEAM_COMPLETION = 'FEED_TEAM_COMPLETION',
  FEED_TEAM_FILE_ADDED = 'FEED_TEAM_FILE_ADDED',
  FEED_TEAM_NEW_EVENT = 'FEED_TEAM_NEW_EVENT',
  FEED_TEAM_OWNER_COMPETENCY_ANALYSIS = 'FEED_TEAM_OWNER_COMPETENCY_ANALYSIS',
  FEED_TEAM_OWNER_CREDIT_EXP = 'FEED_TEAM_OWNER_CREDIT_EXP',
  FEED_TEAM_OWNER_CREDITS_ADDED = 'FEED_TEAM_OWNER_CREDITS_ADDED',
  FEED_TEAM_OWNER_SUB_RENEWAL = 'FEED_TEAM_OWNER_SUB_RENEWAL',
  FEED_TEAM_OWNER_SUB_SUGGESTION = 'FEED_TEAM_OWNER_SUB_SUGGESTION',
  FEED_TEAM_PLAN_COMPLETED = 'FEED_TEAM_PLAN_COMPLETED',
  FEED_TEAM_USER_POST = 'FEED_TEAM_USER_POST',
  FEED_USER_COMPETENCY_ANALYSIS = 'FEED_USER_COMPETENCY_ANALYSIS',
  FEED_USER_COMPLETION = 'FEED_USER_COMPLETION',
  FEED_USER_CUSTOMER_EVENT_ADDED = 'FEED_USER_CUSTOMER_EVENT_ADDED',
  FEED_USER_CUSTOMER_EVENT_CANCELLED = 'FEED_USER_CUSTOMER_EVENT_CANCELLED',
  FEED_USER_CUSTOMER_EVENT_DATE_CHANGED = 'FEED_USER_CUSTOMER_EVENT_DATE_CHANGED',
  FEED_USER_EVENT_UPDATED = 'FEED_USER_EVENT_UPDATED',
  FEED_USER_EXPLORE_ACTIVITIES = 'FEED_USER_EXPLORE_ACTIVITIES',
  FEED_USER_GROUP_SUGGESTION = 'FEED_USER_GROUP_SUGGESTION',
  FEED_USER_LEARN_MORE = 'FEED_USER_LEARN_MORE',
  FEED_USER_LEARNING_PATH_ASSIGNED = 'FEED_USER_LEARNING_PATH_ASSIGNED',
  FEED_USER_PLAN_ASSIGNED = 'FEED_USER_PLAN_ASSIGNED',
  FEED_USER_PLAN_DELETED = 'FEED_USER_PLAN_DELETED',
  FEED_USER_PLAN_DUE = 'FEED_USER_PLAN_DUE',
  FEED_USER_SUBSCRIPTION_ASSIGNED = 'FEED_USER_SUBSCRIPTION_ASSIGNED',
  FEED_USER_UPCOMING_EVENT = 'FEED_USER_UPCOMING_EVENT',
  FEED_USER_WELCOME = 'FEED_USER_WELCOME',
}
