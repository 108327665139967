import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { constants } from '@pepconnect/constants/constants';
import { EventOfferingPresenter } from '@pepconnect/models/events/event-offering-presenter.model';
import { EventOffering } from '@pepconnect/models/events/event-offering.model';

@Component({
  selector: 'app-event-post',
  templateUrl: './event-post.component.html',
  styleUrls: ['./event-post.component.scss']
})
export class EventPostComponent {
  @Input() offering: EventOffering;

  constructor(public translateService: TranslateService) { }

  get backgroundImage() {
    return this.offering?.event?.imagePath || constants.DEFAULT_IMAGE_EVENT;
  }

  getExternalInstructors() {
    const instructors = this.offering?.eventOfferingPersonnel?.filter(p => p.type === 'Instructor')
      .sort((a: EventOfferingPresenter, b: EventOfferingPresenter) => a.firstName > b.firstName ? 1 : -1)
      .map(function (i) {
        return `${i.firstName} ${i.lastName}`;
      });

    return this.getInstructorList(instructors);
  }

  getInstructors() {
    const instructors = this.offering?.instructors?.map(function (i) {
      return i.name;
    });

    return this.getInstructorList(instructors);
  }

  private getInstructorList(instructors: string[]) {
    if (instructors?.length)
      return `${this.translateService.instant('TEXT_WITH')} ${instructors.join(', ')}`;
    else
      return '';
  }
}
