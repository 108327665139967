import { DialogService } from "@ngneat/dialog";
import { BaseModalsService } from "@pepconnect/shared/base/base-modal-service/base-modal-service";
import { UserPostImageViewerComponent } from "../components/user-post/user-post-image-viewer/user-post-image-viewer.component";
import { UserPost } from "../models/user-post.model";
import { Injectable, inject } from "@angular/core";
import { getImageDimensions } from "@pepconnect/utils/image-helpers";
import { firstValueFrom } from "rxjs";
import { WINDOW } from "@pepconnect/providers/window-provider";

@Injectable({
  providedIn: 'root'
})
export class FeedModalService extends BaseModalsService {

  definitions = new ModalDefinitions(this);
  constructor(protected modalService: DialogService) { super(modalService) }
}

class ModalDefinitions {
  private readonly window = inject(WINDOW);
  constructor(private modalsService: FeedModalService) {}

  async openViewImage(post: UserPost) {
    const { height, width } = await firstValueFrom(getImageDimensions(post.mediaUrl));
    if(height > this.window.screen.availHeight) {
      // img is bigger than the screen
      this.modalsService.create(
        UserPostImageViewerComponent.modalId,
        UserPostImageViewerComponent,
        { data: post, size: 'fullscreen', resizable: true, draggable: true }
        )
    } else {
      // img is smaller than the screen
      this.modalsService.create(
        UserPostImageViewerComponent.modalId,
        UserPostImageViewerComponent,
        { data: post, height: height + 100, width: width + 100, minHeight: '30vh', minWidth: '60vh', resizable: true, draggable: true, windowClass: 'img-viewer-modal' }
        )
    }
  }
}
