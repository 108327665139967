import { Injectable } from "@angular/core";
import { tapResponse } from '@ngrx/operators';

import { BaseFeedStore } from "@pepconnect/shared/feed/base/base-feed.store";
import { FeedService } from "@pepconnect/shared/feed/services/feed.service";
import { Observable, switchMap, withLatestFrom } from "rxjs";

@Injectable()
export class ActivityFeedStore extends BaseFeedStore {
  userId: number = 0;
  private readonly INITIAL_PAGE_SIZE: number = 5;

  constructor(
    private feedService: FeedService
  ) {
    super();
  }

  /**
   * call API to get user feed posts
   * 
   * Called once when feed is being initalized for auth users only
   *
   * */
  readonly initializeUserFeed = this.effect((userId$: Observable<number>) => {
    return userId$.pipe(
      switchMap((userId) => {
        this.userId = userId;
        this.setError(null);
        this.setLoading(true);
        // call API to first user posts
        return this.feedService.fetchInitialUserPosts(this.INITIAL_PAGE_SIZE).pipe(
          tapResponse(
            (feedResults) => {
              this.setPageNumber(1);
              this.setPosts(feedResults.posts);
              this.setHasMoreResults(feedResults.hasMoreResults);
              this.setLoading(false);
            },
            (err: any) => {
              this.onApiError(err);
            }));
      }))
  });

  /**
   * call API to get a specific number of user feed posts
   * 
   * Called to refresh on a change while preserving page state
   * */
  readonly reloadFeedRange = this.effect((feedRequest$: Observable<{userId: number, pageSize: number}>) => {
    return feedRequest$.pipe(
      switchMap((request) => {
        this.userId = request.userId;
        this.setError(null);
        this.setLoading(true);
        // call API to first user posts
        return this.feedService.fetchInitialUserPosts(request.pageSize).pipe(
          tapResponse(
            (feedResults) => {
              this.setPosts(feedResults.posts);
              this.setHasMoreResults(feedResults.hasMoreResults);
              this.setLoading(false);
            },
            (err: any) => {
              this.onApiError(err);
            }));
      }))
  });
  
  /**
 * call API to get next page of user feed posts
 * 
 * Called when "load more" is selected
 *
 * */
  readonly loadNextUserFeedPage = this.effect((userId$: Observable<number>) => {
    return userId$.pipe(
      withLatestFrom(this.pageNumber$, this.posts$),
      switchMap(([userId, pageNumber, posts]) => {
        if (userId !== this.userId) {
          // user has changed since we loaded, reload feed
          this.initializeUserFeed(userId);
          return;
        }
          
        this.setError(null);
        this.setLoading(true);
        const lastActivityPostId = posts[posts.length - 1].id;
        const nextPageNumber = pageNumber + 1;
        // call API to first user posts
        return this.feedService.fetchNextUserPosts(this.PAGE_SIZE, nextPageNumber, lastActivityPostId).pipe(
          tapResponse(
            (feedResults) => {
              this.addPosts(feedResults.posts);
              this.setPageNumber(nextPageNumber);
              this.setHasMoreResults(feedResults.hasMoreResults);
              this.setLoading(false);
            },
            (err: any) => {
              this.onApiError(err);
            }));
      }))
  });
}
