import { Component, Input, inject } from '@angular/core';
import { UserPost } from '@pepconnect/shared/feed/models/user-post.model';
import { FeedModalService } from '@pepconnect/shared/feed/services/feed-modal.service';

/** Display either image or video */

@Component({
  selector: 'app-user-post-media',
  templateUrl: './user-post-media.component.html',
})
export class UserPostMediaComponent {
  @Input() post: UserPost;
  private readonly feedModalService = inject(FeedModalService);

  viewImage() {
    this.feedModalService.definitions.openViewImage(this.post);
  }
}
