import { Component } from '@angular/core';
import { BaseVideoPlayerComponent } from '@pepconnect/shared/base/base-video-player/base-video-player.component';

/** extend base video player */

@Component({
  selector: 'app-user-post-video-player',
  templateUrl: './../../../../base/base-video-player/base-video-player.component.html'
})
export class UserPostVideoPlayerComponent extends BaseVideoPlayerComponent {

}
