import { Component, inject, Input, OnInit } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants/constants';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { UserService } from '@pepconnect/services/user.service';
import { BaseFeedComponent } from '@pepconnect/shared/feed/base/base-feed.component';
import { ActivityFeedStore } from '@pepconnect/shared/feed/components/activity-feed/activity-feed.store';
import { TeamFeedStore } from '@pepconnect/shared/feed/components/team-feed/team-feed.store';
import { ActivityPost } from '@pepconnect/shared/feed/models/activity-post.model';
import { AppState } from '@pepconnect/state';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { combineLatest, forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserPostEvent } from '../../models/user-post-event.model';

@Component({
  selector: 'app-team-feed',
  templateUrl: './team-feed.component.html',
  styleUrls: ['./team-feed.component.scss'],
  providers: [provideComponentStore(TeamFeedStore), provideComponentStore(ActivityFeedStore)]
})
export class TeamFeedComponent extends BaseFeedComponent implements OnInit {
  @Input() member: TeamMember;
  private readonly userService = inject(UserService);
  private readonly teamStore = inject(TeamStore);

  readonly authState$ = this.store.select(selectAuthState);
  readonly teamMember$ = this.teamStore.$teamMember;
  readonly posts$ = this.teamFeedStore.posts$;
  readonly hasMoreResults$ = this.teamFeedStore.hasMoreResults$;
  loadFeed: boolean = false;

  constructor(protected store: Store<AppState>, protected teamFeedStore: TeamFeedStore) {
    super(store, teamFeedStore);
  }

  // initialize
  ngOnInit(): void {
    this.setFlags();
  }

  /**
   * Clears out any existing feed in the store, load current feed
   * */
  setupFeed() {
    forkJoin([this.authState$.pipe(take(1)), this.teamMember$.pipe(take(1))])
      .subscribe(([authState, teamMember]) => {
        this.teamFeedStore.setError(null);

        // double check that team member is valid and
        if (!teamMember?.team?.teamID) {
          this.teamFeedStore.setError('No valid team member.');
        } else {
          // double check that user is logged in, not impersonating, throw error message
          if (!authState.isInitialized || !authState.isLoggedIn || authState.isImpersonating) {
            this.teamFeedStore.setError('You must be logged in and not impersonating to view your activity feed.');
          }
          else {
            // user is logged in and not impersonating, setup the store
            return this.teamFeedStore.initializeTeamFeed(teamMember);
          }
        }
      });
  }


  /**
   * Called when user selects "Load More"
   * */
  loadMore() {
    this.teamMember$.pipe(take(1))
      .subscribe((teamMember) => {

        if (teamMember) { // user is logged in and not impersonating, setup the store
          this.teamFeedStore.loadNextTeamFeedPage(teamMember);
        }
      });
  }

  feedTrackBy(_, post: ActivityPost) {
    return post.id;
  }

  setFlags() {
    this.userService.checkFeatureFlag(constants.FLAG_LOAD_FEED)
      .pipe(take(1))
      .subscribe(flag => {
        this.loadFeed = !!flag;
        if (this.loadFeed) {
          this.setupFeed();
        }
      });
  }

  showFeed() {
    this.loadFeed = true;
    this.setupFeed();
  }

  refreshPage() {
    combineLatest([this.authState$, this.teamMember$, this.posts$]).pipe(take(1))
      .subscribe(([authState, teamMember, posts]) => {
        this.teamFeedStore.setError(null);

        // if user is not logged in or impersonating, throw message
        if (!authState.isInitialized || !authState.isLoggedIn || authState.isImpersonating) {
          this.teamFeedStore.setError('You must be logged in and not impersonating to view your activity feed.');
        }
        else { // user is logged in and not impersonating, setup the store
          this.teamFeedStore.reloadFeedRange({ teamMember: teamMember, pageSize: posts.length });
        }
      });
  }

  applyComment(event: UserPostEvent) {
    this.teamFeedStore.addComment(event);
  }

  deleteComment(userPostId: string) {
    this.teamFeedStore.deleteComment(userPostId);
  }
}
