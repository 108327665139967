<ng-template #commentTemplate let-c>
    <div class="post-content">
        <app-user-comment
            #editComment
            [commentPost]="c.comment"
             placeholder=""
             saveButtonText="BUTTON_SAVE"
             [isActivityPage]="isActivityPage"
             [team]="team"
             (onUserPostComment)="relayEvent($event)"
             [isDepthLevelOne]="c.depth === 1"></app-user-comment>

        <!-- Comment Form -->
        <small class="reply-button" (click)="c.replyExpanded = !c.replyExpanded" *ngIf="c.depth > 1 && c.depth < 3">
            {{'TEXT_REPLY' | translate}}
        </small>
        <app-user-comment
            *ngIf="c.depth < 3 && c.replyExpanded"
            [parentCommentPost]="c.comment"
            [placeholder]="'ACTIVITY_USERPOST_PLACEHOLDER_COMMENT' | translate"
            [saveButtonText]="c.depth == 1 ? 'BUTTON_COMMENT' : 'TEXT_REPLY'"
            [isActivityPage]="isActivityPage"
            [team]="team"
            (onUserPostComment)="relayEvent($event)"
            [isDepthLevelOne]="c.depth === 1">
        </app-user-comment>
        <div class="comment-hr" *ngIf="c.depth === 1"></div>

        <!-- Hide / Show -->
        <div class="hide-show-selectors" *ngIf="c.comment.comments?.length > 0">
            <div *ngIf="c.comment.comments?.length && c.showComments" (click)="c.showComments = false">
                {{hideCommentLabel(c.comment.comments?.length, c.depth > 1)}}
            </div>
            <div *ngIf="c.comment.comments?.length && !c.showComments" (click)="c.showComments = true">
                {{showCommentLabel(c.comment.comments?.length, c.depth > 1)}}
            </div>
        </div>

        <!-- Replies -->
        <ul class="replies" *ngIf="c.showComments && c.comment.comments?.length > 0">
            <li class="user-post" *ngFor="let reply of c.comment.comments">
                <ng-template [ngTemplateOutlet]="commentTemplate"
                    [ngTemplateOutletContext]="{ $implicit: { comment: reply, depth: c.depth + 1, showComments: true, replyExpanded: false } }">
                </ng-template>
            </li>
        </ul>
    </div>
    <uimc-custom-dropdown class="user-post-ellipsis" #userpostdd>
        <div slot="content">
            <span class="icon-dots-ellipsis-horizontal"></span>
        </div>
        <uimc-menu>
            <uimc-menu-item *ngFor="let action of c.comment.actions"
                (click)="fileAction(editComment, c.comment, action, $event, userpostdd)">
                {{action.text | translate}}
            </uimc-menu-item>
        </uimc-menu>
    </uimc-custom-dropdown>
</ng-template>


<div class="user-post-container">
    <div class="user-post" *ngIf="post?.userPost">
        <ng-template [ngTemplateOutlet]="commentTemplate"
            [ngTemplateOutletContext]="{ $implicit: { comment: post.userPost, depth: 1, showComments: true, replyExpanded: true } }">
        </ng-template>
    </div>
</div>
