<app-post-container [type]="post.activityType">
    <p [innerHtml]="post.message" class="margin-bottom-0"></p>
    <span class="font-size-xsmall">{{post.date | date: 'longDate'}} {{post.date | date: 'shortTime'}}</span>
    <!-- child component alphabetically organized-->
    <app-content-post *ngIf="post?.learningObject"
                      (click)="gotoPage(post?.learningObject)"
                      [content]="post?.learningObject"
                      class="selectable">
    </app-content-post>
    <app-event-post *ngIf="post?.offering"
                    (click)="gotoPage(post?.offering)"
                    [offering]="post?.offering"
                    class="selectable">
    </app-event-post>
    <app-plan-post *ngIf="post?.plan"
                   (click)="isPlanSelectable() && gotoPlan()"
                   [plan]="post?.plan"
                   [class.selectable]="isPlanSelectable()"
                   [class.disabled]="!isPlanSelectable()">
    </app-plan-post>
    <app-subscription-post *ngIf="post?.subscription"
                           (click)="gotoPage(post?.subscription)"
                           [subscription]="post?.subscription"
                           [team]="post?.team"
                           class="selectable">
    </app-subscription-post>
    <app-team-post *ngIf="post?.suggestedTeam?.team"
                   (click)="gotoPage(post?.suggestedTeam?.team)"
                   [teamMember]="post?.suggestedTeam"
                   class="selectable">
    </app-team-post>
    <app-video-post *ngIf="post?.video">
    </app-video-post>
    <!-- end child components -->
    <app-action-buttons *ngIf="post?.actions?.length">
        <ng-container *ngFor="let action of post?.actions">
            <button *ngIf="showNonShareLink(action)"
                    type="button"
                    (click)="linkClick(action)"
                    [ngClass]="{'button': true,'button--primary': isPrimaryLink(action), 'button--secondary': !isPrimaryLink(action), 'hidden': action.cssClass === 'btn-hidden' }"
                    [id]="'post-tl-' + post.id + '_action_' + action.rel">
                {{ action?.text | translate }}
            </button>
            <button *ngIf="isShareLink(action)"
                    type="button"
                    (click)="shareClick()"
                    class="button button--secondary button-with-icon icon-share">
                {{ action?.text | translate }}
            </button>
        </ng-container>
    </app-action-buttons>
    <div class="eula-link"
         *ngIf="hasEulaLink()"
         [id]="'post-tl-' + post.id + '_action_eula'">
        {{'EULA_PRELINK_TEXT' | translate}} <app-eula-link></app-eula-link>
    </div>
</app-post-container>

