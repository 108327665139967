import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResponseMessage, UnserializedResponseMessage } from '@pepconnect/models/general/response-message.model';
import { ApiService } from '@pepconnect/services/api.service';
import { BaseStateService } from '@pepconnect/shared/base/base-state-service/base-state-service';
import { ActivityType } from '@pepconnect/shared/feed/enums/activity-type.enum';
import { ActivityPost } from '@pepconnect/shared/feed/models/activity-post.model';
import { FeedResults } from '@pepconnect/shared/feed/models/feed-results.model';
import { UserPost } from '@pepconnect/shared/feed/models/user-post.model';
import { AppState } from '@pepconnect/state';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';

enum ActivityIcon {
  Bulb = 'custom-icon icon-bulb',
  Calendar = 'icon-calendar',
  Check = 'icon-check',
  Comment = 'icon-comment',
  Document = 'icon-document',
  Duration = 'icon-duration',
  Information = 'icon-information',
  Locked = 'icon-locked',
  Reload = 'icon-reload',
  Search = 'icon-search',
  World = 'icon-world',
}


@Injectable({
  providedIn: 'root'
})
export class FeedService extends BaseStateService {
  $authState = this.store.select(selectAuthState);

  private readonly activityClassMap = new Map<string, string>([
    [ActivityType.FEED_TEAM_COMPLETION, ActivityIcon.Check],
    [ActivityType.FEED_TEAM_FILE_ADDED, ActivityIcon.Document],
    [ActivityType.FEED_TEAM_NEW_EVENT, ActivityIcon.Calendar],
    [ActivityType.FEED_TEAM_OWNER_COMPETENCY_ANALYSIS, ActivityIcon.Calendar],
    [ActivityType.FEED_TEAM_OWNER_CREDIT_EXP, ActivityIcon.Duration],
    [ActivityType.FEED_TEAM_OWNER_CREDITS_ADDED, ActivityIcon.Reload],
    [ActivityType.FEED_TEAM_OWNER_SUB_RENEWAL, ActivityIcon.Duration],
    [ActivityType.FEED_TEAM_OWNER_SUB_SUGGESTION, ActivityIcon.Bulb],
    [ActivityType.FEED_TEAM_PLAN_COMPLETED, ActivityIcon.Check],
    [ActivityType.FEED_TEAM_USER_POST, ActivityIcon.Comment],
    [ActivityType.FEED_USER_COMPETENCY_ANALYSIS, ActivityIcon.Check],
    [ActivityType.FEED_USER_COMPLETION, ActivityIcon.Check],
    [ActivityType.FEED_USER_CUSTOMER_EVENT_ADDED, ActivityIcon.Calendar],
    [ActivityType.FEED_USER_CUSTOMER_EVENT_CANCELLED, ActivityIcon.Calendar],
    [ActivityType.FEED_USER_CUSTOMER_EVENT_DATE_CHANGED, ActivityIcon.Calendar],
    [ActivityType.FEED_USER_EVENT_UPDATED, ActivityIcon.Calendar],
    [ActivityType.FEED_USER_EXPLORE_ACTIVITIES, ActivityIcon.Search],
    [ActivityType.FEED_USER_GROUP_SUGGESTION, ActivityIcon.Bulb],
    [ActivityType.FEED_USER_LEARN_MORE, ActivityIcon.Information],
    [ActivityType.FEED_USER_LEARNING_PATH_ASSIGNED, ActivityIcon.Locked],
    [ActivityType.FEED_USER_PLAN_ASSIGNED, ActivityIcon.Locked],
    [ActivityType.FEED_USER_PLAN_DELETED, ActivityIcon.Locked],
    [ActivityType.FEED_USER_PLAN_DUE, ActivityIcon.Locked],
    [ActivityType.FEED_USER_SUBSCRIPTION_ASSIGNED, ActivityIcon.Document],
    [ActivityType.FEED_USER_UPCOMING_EVENT, ActivityIcon.Calendar],
    [ActivityType.FEED_USER_WELCOME, ActivityIcon.World]
  ]);

  constructor(
    protected store: Store<AppState>,
    private api: ApiService
  ) {
    super(store);
  }

  /**
   *
   * Called when user feed is first loaded.  Gets the first page of user activity posts
   *
   * */
  fetchInitialUserPosts(pageSize: number) {
    return this.api.get<FeedResults>(`activity/${pageSize}/1`);
  };

  /**
   *
   * Called for subsequent "load more" request for user activity posts
   *
   * */
  fetchNextUserPosts(pageSize: number, pageNumber: number, lastActivityFeedId: string) {
    return this.api.get<FeedResults>(`activity/${pageSize}/${pageNumber}/${lastActivityFeedId}`);
  };

  /**
   *
   * Called when team feed is first loaded.  Gets the first page of team activity posts
   *
   * */
  fetchInitialTeamPosts(teamId: string, pageSize: number) {
    return this.api.get<FeedResults>(`teams/${teamId}/activity/${pageSize}/1`);
  };

  /**
   *
   * Called for subsequent "load more" request for team activity posts
   *
   * */
  fetchNextTeamPosts(teamId: string, pageSize: number, pageNumber: number, lastActivityFeedId: string) {
    return this.api.get<FeedResults>(`teams/${teamId}/activity/${pageSize}/${pageNumber}/${lastActivityFeedId}`);
  };

  /**
   *
   * posts a new userPost to a team or user activity feed
   *
   * returns the new post
   *
   * */
  addUserPost(userPost: UserPost, teamId: string) {
    return this.api.post<ActivityPost>(`teams/${teamId}/activity`, userPost);
  }

  uploadPostMedia(teamId: string, file: File) {
    return this.api.postFile<UnserializedResponseMessage>(`teams/${teamId}/activity/upload`, file);
  }

  /**
   *
   * posts an update to a userPost
   *
   * apiPath should look like this:
   * `teams/${teamId}/activity/${userPostId}`
   *
   * */
  editUserPost(userPost: UserPost) {
    // Note: may be able to use LinkService for this
    return this.api.put<ResponseMessage>(`teams/${userPost.teamID}/activity/${userPost.userPostID}`, userPost);
  }

  /**
   *
   * deletes a userPost
   *
   * apiPath should look like this:
   * `teams/${teamId}/activity/${userPostId}`
   *
   * */
  deleteUserPost(teamId: string, userPostId: string) {
    // Note: may be able to use LinkService for this
    return this.api.delete<UnserializedResponseMessage>(`teams/${teamId}/activity/${userPostId}`);
  }

  /**
   *
   * flags a userPost
   *
   * apiPath should look like this:
   * `teams/${teamId}/activity/${userPostId}/flag`
   *
   * For owners only, returns a updated version of the post
   *
   * */
  flagUserPost(userPost: UserPost) {
    // Note: may be able to use LinkService for this
    return this.api.put<UserPost>(`teams/${userPost.teamID}/activity/${userPost.userPostID}/flag`, userPost);
  }

  /**
   *
   * unflags a userPost
   *
   * apiPath should look like this:
   * `teams/${teamId}/activity/${userPostId}/unflag`
   *
   * For owners only, returns a updated version of the post
   *
   * */
  unflagUserPost(userPost: UserPost) {
    // Note: may be able to use LinkService for this
    return this.api.put<UserPost>(`teams/${userPost.teamID}/activity/${userPost.userPostID}/unflag`, userPost);
  }

  /**
   *
   * post a comment to an existing userPost
   *
   * returns new version of top-level userPost with the new comment on it
   *
   * */
  postComment(teamId: string, userPostId: string, comment: UserPost) {
    return this.api.post<UserPost>(`teams/${teamId}/activity/${userPostId}/comments`, comment);
  };

  /**
   *
   * get the css class associated with an activity type
   *
   * */
  getActivityIconClass(activityType: ActivityType) {
    return this.activityClassMap.get(activityType);
  };
}
