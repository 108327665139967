<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async,
    posts: posts$ | async,
    hasMoreResults: hasMoreResults$ | async
    } as feedState">
    <app-collection>
        <app-collection-item [ngClass]="{'margin-bottom-half': loadFeed && !showEpt}">
            <app-collection-item-header>
                {{ 'TITLE_ACTIVITYFEED' | translate }}
            </app-collection-item-header>
        </app-collection-item>
        <app-collection-item *ngIf="showEpt" [ngClass]="{'margin-bottom-half': loadFeed}">
            <div class="row margin-left-1">
                <div class="col-md-9">
                    <h6 class="display-flex margin-top-0">{{'EP_TOOL_LABEL' | translate}}</h6>
                    <p class="display-flex margin-bottom-0">{{'EP_TOOL_DESC' | translate}}</p>
                </div>
                <div class="col-md-3">
                    <button class="button button--primary">{{ 'EP_TOOL_BUTTON_LABEL' | translate }}</button>
                </div>
            </div>
        </app-collection-item>
        <app-collection-item class="margin-bottom-half" *ngIf="!loadFeed">
            <button [disabled]="feedState.loading"
                    id="activity-show-feed"
                    class="button button--primary"
                    type="button"
                    (click)="showFeed()">
                {{'ACTIVITY_FEED_SHOW' | translate}}
            </button>
        </app-collection-item>
        <app-collection-item class="margin-bottom-half"
                             *ngFor="let post of feedState.posts; index as i; trackBy: feedTrackBy">
            <app-activity-post *ngIf="!post?.userPost" [post]="post" (onSuccessAction)="refreshPage()"></app-activity-post>
            <app-user-post *ngIf="post?.userPost"
                [post]="post" 
                [isActivityPage]="true" 
                (onPostModify)="refreshPage()" 
                (onUserPostComment)="applyComment($event)"
                (onUserPostDelete)="deleteComment($event)"></app-user-post>
        </app-collection-item>
    </app-collection>
    <app-action-buttons>
        <button *ngIf="loadFeed && !feedState.loading && feedState.hasMoreResults"
                id="activity-load-more"
                class="button button--secondary"
                type="button"
                (click)="loadMore()">
            {{'BUTTON_LOADMORE' | translate}}
        </button>
        <app-spinner *ngIf="feedState.loading"></app-spinner>
        <div *ngIf="feedState.error" id="error-panel">
            <app-alert indicator="error" [accent-border]="true">
                {{ feedState.error | translate }}
            </app-alert>
            <br />
        </div>
    </app-action-buttons>
</ng-container>
