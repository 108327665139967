import { Injectable } from "@angular/core";
import { tapResponse } from '@ngrx/operators';

import { TeamMember } from "@pepconnect/features/teams/models/team-member.model";
import { BaseFeedStore } from "@pepconnect/shared/feed/base/base-feed.store";
import { FeedService } from "@pepconnect/shared/feed/services/feed.service";
import { Observable, switchMap, withLatestFrom } from "rxjs";

@Injectable()
export class TeamFeedStore extends BaseFeedStore {
  constructor(
    private feedService: FeedService
  ) {
    super();
  }

  /**
   * call API to get team feed posts
   * 
   * Called once when feed is being initalized for auth users only
   *
   * */
  readonly initializeTeamFeed = this.effect((teamMember$: Observable<TeamMember>) => {
    return teamMember$.pipe(
      switchMap((teamMember) => {
        this.setError(null);
        this.setLoading(true);
        // call API to first user posts
        return this.feedService.fetchInitialTeamPosts(teamMember?.team?.teamID, this.PAGE_SIZE).pipe(
          tapResponse(
            (feedResults) => {
              this.setPosts(feedResults.posts);
              this.setPageNumber(1);
              this.setHasMoreResults(feedResults.hasMoreResults);
              this.setLoading(false);
            },
            (err: any) => {
              // note:  API returns 404 here when there are no posts
              // we want to ignore that error and just show an empty feed
              // 500 errors and other serious errors are handled by http interceptor
              this.setHasMoreResults(false);
              this.setLoading(false);
            }));
      }))
  });

  /**
   * call API to get a specific number of user feed posts
   * 
   * Called when the page needs to be refreshed in its current state
   * */
  readonly reloadFeedRange = this.effect((feedRequest$: Observable<{ teamMember: TeamMember, pageSize: number }>) => {
    return feedRequest$.pipe(
      switchMap((request) => {
        this.setError(null);
        this.setLoading(true);
        // call API to first user posts
        return this.feedService.fetchInitialTeamPosts(request.teamMember?.team?.teamID, request.pageSize).pipe(
          tapResponse(
            (feedResults) => {
              this.setPosts(feedResults.posts);
              this.setHasMoreResults(feedResults.hasMoreResults);
              this.setLoading(false);
            },
            (err: any) => {
              // note:  API returns 404 here when there are no posts
              // we want to ignore that error and just show an empty feed
              // 500 errors and other serious errors are handled by http interceptor
              this.setHasMoreResults(false);
              this.setLoading(false);
            }));
      }));
  });

  /**
 * call API to get next page of team feed posts
 * 
 * Called when "load more" is selected
 *
 * */
  readonly loadNextTeamFeedPage = this.effect((teamMember$: Observable<TeamMember>) => {
    return teamMember$.pipe(
      withLatestFrom(this.pageNumber$, this.posts$),
      switchMap(([teamMember, pageNumber, posts]) => {
        this.setError(null);
        this.setLoading(true);
        const lastActivityPostId = posts[posts.length - 1].id;
        const nextPageNumber = pageNumber + 1;
        return this.feedService.fetchNextTeamPosts(teamMember?.team?.teamID, this.PAGE_SIZE, nextPageNumber, lastActivityPostId).pipe(
          tapResponse(
            (feedResults) => {
              this.addPosts(feedResults.posts);
              this.setPageNumber(nextPageNumber);
              this.setHasMoreResults(feedResults.hasMoreResults);
              this.setLoading(false);
            },
            (err: any) => {
              this.onApiError(err);
            }));
      }))
  });
}
