import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { TermTypes } from '@pepconnect/enums/term-types.enum';
import { CartService } from '@pepconnect/features/checkout/services/cart.service';
import { TeamsModalsService } from '@pepconnect/features/teams/services/teams-modals.service';
import { TeamsService } from '@pepconnect/features/teams/services/teams.service';
import { CertificateItem } from '@pepconnect/models/certificates/certificate.model';
import { Link } from '@pepconnect/models/general/link.model';
import { ResponseMessage } from '@pepconnect/models/general/response-message.model';
import { AnalyticsService } from '@pepconnect/services/analytics.service';
import { EventsService } from '@pepconnect/services/content/events.service';
import { LinksService } from '@pepconnect/services/links.service';
import { ModalsService } from '@pepconnect/services/modals.service';
import { SubscriptionService } from '@pepconnect/services/subscription.service';
import { WindowPopupService } from '@pepconnect/services/window-popup.service';
import { BaseFeedComponent } from '@pepconnect/shared/feed/base/base-feed.component';
import { ActivityFeedStore } from '@pepconnect/shared/feed/components/activity-feed/activity-feed.store';
import { ActivityType } from '@pepconnect/shared/feed/enums/activity-type.enum';
import { ActivityPost } from '@pepconnect/shared/feed/models/activity-post.model';
import { AppState } from '@pepconnect/state/index';
import { switchMap, take } from 'rxjs';

declare const cloudMedia: any;

@Component({
  selector: 'app-activity-post',
  templateUrl: './activity-post.component.html',
  styleUrls: ['./activity-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityPostComponent extends BaseFeedComponent {
  @Input() post: ActivityPost;
  @Output() onSuccessAction = new EventEmitter<boolean>();

  constructor(
    protected store: Store<AppState>,
    protected activityFeedStore: ActivityFeedStore,
    private router: Router,
    private modalService: ModalsService,
    private linksService: LinksService,
    private windowPopup: WindowPopupService,
    private translateService: TranslateService,
    private teamsService: TeamsService,
    private cartService: CartService,
    private eventsService: EventsService,
    private teamsModalsService: TeamsModalsService,
    private analyticsService: AnalyticsService,
    private subscriptionService: SubscriptionService,
  ) {
    super(store, activityFeedStore);
  }

  gotoPage(item: any) {
    this.linksService.gotoPage(item);
  }

  gotoPlan() {
    if (this.post?.plan?.active) {
      this.$authState.pipe(take(1))
        .subscribe(authState => {
          this.router.navigate([authState.user.locale.locale, 'plan']);
        });
    }
  }

  isShareLink(action: Link) {
    return action?.rel === LinkRel.activityShare || action.rel === LinkRel.activityShareObject;
  }

  showNonShareLink(action: Link) {
  // to hide the member join/accept/deny links
    return !this.isShareLink(action) &&
      action?.rel !== LinkRel.memberRequestAccess &&
      action?.rel !== LinkRel.memberAcceptInvite &&
      action?.rel !== LinkRel.memberDenyInvite
  }


  isPrimaryLink(action: Link) {
    // we are trying not to use .cssClass
    // the activity feed is going away
    // referring to it here temporarily to avoid having to remap all the actions to primary/secondary actions or update API
    return action.cssClass === 'btn-primary' || action.cssClass === 'btn-pay';
  }

  hasEulaLink() {
    // don't show EULA for group member actions, they just redirect to group page
    return this.post?.actions?.some(a => a.requireEULA && a.rel !== LinkRel.memberRequestAccess && a.rel !== LinkRel.memberDenyInvite && a.rel !== LinkRel.memberAcceptInvite);
  }

  linkClick(action: Link) {
    switch (action?.rel) {
      case LinkRel.activityAbout:
      case LinkRel.activityExplore:
      case LinkRel.activityGroupDetails:
      case LinkRel.activityFindSubscription:
      case LinkRel.activitySearchGroups:
      case LinkRel.page:
      case LinkRel.activityAddCredits:
      case LinkRel.join:
        if (this.post?.plan)
          this.gotoPlan();
        else
          this.linksService.gotoLink(action);
        break;
      case LinkRel.activityLaunchPromo:
        this.windowPopup.openPopupWindow(
          cloudMedia.Utils.cloudTransformUrl(this.translateService.instant(action.href))
        );
        break;
      case LinkRel.ownerPurchase:
      case LinkRel.ownerRenew:
        this.ownerPurchaseRenew();
        break;
      case LinkRel.printCertificate:
        this.printCertificate();
        break;
      case LinkRel.sso:
      case LinkRel.external:
        this.launchSubscriptionWithPrivacyCheck(action);
        break;
      case LinkRel.unregister:
        this.cancelRegistration();
        break;
      default:
        this.doPostAction(action);
        break;

    }
  }

  launchSubscription(action: Link) {
    this.$userCountry.pipe(take(1), switchMap(c => this.subscriptionService.recordLaunch(c.code, this.post?.subscription?.friendlyURL)))
      .pipe(take(1))
      .subscribe({
        next: () => {
          if (action.rel === LinkRel.external) {
            this.launchSubscriptionExternal();
          } else {
            this.windowPopup.openPopupWindow(action.href);
          }
        }, error: (err) => {
          this.activityFeedStore.setError(err);
        }
      });
  }

  launchSubscriptionExternal() {
    this.subscriptionService.getExternalLaunch(this.post?.subscription?.subscriptionID)
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          this.windowPopup.openPopupWindow(res);
        }, error: (err) => {
          this.activityFeedStore.setError(err);
        }
      });
  }


  // don't allow them to select completed plans
  isPlanSelectable() {
    return this.post?.activityType !== ActivityType.FEED_TEAM_PLAN_COMPLETED;
  }

  shareClick() {
    this.modalService.definitions.openSocialLinksModal();
  }

  cancelRegistration() {
    if (this.post?.offering?.isRefundable && this.post?.offering?.orderGuid) {
      this.modalService.definitions.openRefundModal(this.post?.offering?.orderGuid).afterClosed$.subscribe((r) => {
        if (r?.refundSuccess) {
          this.doPostEventCancel()
        }
      });
    }
    else {
      this.eventsService.cancelRegistration(this.post?.offering)
        .pipe(take(1))
        .subscribe(
          () => this.doPostEventCancel()
        );
    }
  }

  private doPostAction(action: Link) {
    this.linksService.postAction(action, true).pipe(take(1))
      .subscribe((res) => {
        this.analyticsService.trackGroup({ action: 'group.join', data: { group: { name: this.post?.team?.name } } });
        this.onSuccessAction.emit(true);
      });
  }

  private doPostEventCancel() {
    this.onSuccessAction.emit(true);
    // dispatch message to other components
  }

  private sendMemberRequest(action: Link) {
    this.linksService.postAction<ResponseMessage>(action, true).pipe(take(1)).subscribe(res => {
      if ((res).message === 'MEMBER_INVITE_COUNTRY_WARNING') {
        this.teamsModalsService.definitions.openGroupCountryWarningModal();
      }
      this.onSuccessAction.emit(true);
    });
  }

  private ownerPurchaseRenew() {
    if ((this.post?.team?.friendlyURL) && (this.post?.subscription?.subscriptionID)) {
      this.teamsService.fetchTeamMember(this.post?.team?.friendlyURL).pipe(take(1)).
        subscribe(teamMember => {
          const orderItem = { subscriptionId: this.post.subscription.subscriptionID, itemType: 14, teamMemberId: teamMember.id };
          this.cartService.initialize(orderItem, this.router.url);
          this.cartService.checkout();
        })
    }
  }

  private printCertificate() {
    // could be a learningObject cert or event offering cert
    // so prepare the certificateItem for modal data
    const item: CertificateItem = {
      title: this.post?.offering?.name ? this.post?.offering?.name : this.post?.learningObject?.title, // modal title
      links: this.post?.offering?.links ? this.post?.offering?.links : this.post?.learningObject?.links, // gets 'self' link to fetch all certificates
      instructors: this.post?.offering?.instructors ? this.post?.offering?.instructors : null, // identifier for Events
      roster: this.post?.offering?.roster ? this.post?.offering?.roster : null, // identifier for Events
      id: this.post?.learningObject?.id ? this.post?.learningObject?.id : null, // identifier for LearningObject
      completionTypeId: this.post?.learningObject?.completionTypeId ? this.post?.learningObject?.completionTypeId : null // identifier for LearningObject
    }

    this.modalService.definitions.openCertificateModal(item, false, '');
  }

  private launchSubscriptionWithPrivacyCheck(action: Link) {
    if (this.post?.subscription?.needsPrivacyNotice) {
      this.modalService.definitions.openSubscriptionTermsModal(TermTypes.SSOTerms, this.post?.subscription?.subscriptionID).subscribe(result => {
        // If true result, member has accepted terms
        if (result) {
          this.launchSubscription(action);
        }
      });
    }
    else {
      this.launchSubscription(action);
    }
  }

}
