import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { BaseSmartComponent } from "@pepconnect/shared/base/base-smart-component/base-smart.component";
import { BaseFeedStore } from '@pepconnect/shared/feed/base/base-feed.store';
import { AppState } from "@pepconnect/state/index";

@Component({ template: '' })
export class BaseFeedComponent extends BaseSmartComponent {
  readonly error$ = this.baseFeedStore.error$;
  readonly loading$ = this.baseFeedStore.loading$;

  constructor(
    protected store: Store<AppState>,
    protected baseFeedStore: BaseFeedStore,
  ) {
    super(store);
  }
}
