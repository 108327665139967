import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MarcomModule } from '@pepconnect-marcom/marcom.module';
import { TeamsSharedModule } from '@pepconnect/features/teams/teams-shared/teams-shared.module';
import { BaseFeedComponent } from '@pepconnect/shared/feed/base/base-feed.component';
import { ActivityFeedComponent } from '@pepconnect/shared/feed/components/activity-feed/activity-feed.component';
import { ActivityPostComponent } from '@pepconnect/shared/feed/components/activity-post/activity-post.component';
import { ContentPostComponent } from '@pepconnect/shared/feed/components/activity-post/content-post/content-post.component';
import { EventPostComponent } from '@pepconnect/shared/feed/components/activity-post/event-post/event-post.component';
import { PlanPostComponent } from '@pepconnect/shared/feed/components/activity-post/plan-post/plan-post.component';
import { SubscriptionPostComponent } from '@pepconnect/shared/feed/components/activity-post/subscription-post/subscription-post.component';
import { TeamPostComponent } from '@pepconnect/shared/feed/components/activity-post/team-post/team-post.component';
import { VideoPostComponent } from '@pepconnect/shared/feed/components/activity-post/video-post/video-post.component';
import { PostContainerComponent } from '@pepconnect/shared/feed/components/post-container/post-container.component';
import { TeamFeedComponent } from '@pepconnect/shared/feed/components/team-feed/team-feed.component';
import { UserCommentComponent } from '@pepconnect/shared/feed/components/user-post/user-comment/user-comment.component';
import { UserPostComponent } from '@pepconnect/shared/feed/components/user-post/user-post.component';
import { SharedModule } from '@pepconnect/shared/module';
import { ClipboardModule } from 'ngx-clipboard';
import { NewUserPostFormComponent } from './components/team-feed/new-user-post-form/new-user-post-form.component';
import { PostFileUploaderComponent } from './components/user-post/post-file-uploader/post-file-uploader.component';
import { UserPostMediaComponent } from './components/user-post/user-post-media/user-post-media.component';
import { UserPostVideoPlayerComponent } from './components/user-post/user-post-video-player/user-post-video-player.component';
import { UserPostImageViewerComponent } from './components/user-post/user-post-image-viewer/user-post-image-viewer.component';

@NgModule({
  declarations: [
    BaseFeedComponent,
    ActivityFeedComponent,
    TeamFeedComponent,
    ActivityPostComponent,
    UserPostComponent,
    UserCommentComponent,
    SubscriptionPostComponent,
    ContentPostComponent,
    EventPostComponent,
    PlanPostComponent,
    TeamPostComponent,
    VideoPostComponent,
    PostContainerComponent,
    PostFileUploaderComponent,
    NewUserPostFormComponent,
    UserPostVideoPlayerComponent,
    UserPostMediaComponent,
    UserPostImageViewerComponent
  ],
  exports: [
    ActivityFeedComponent,
    TeamFeedComponent,
    PostFileUploaderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MarcomModule,
    TranslateModule.forChild({
      extend: true
    }),
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    TeamsSharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FeedModule { }
