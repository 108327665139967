<div class="display-flex margin-top-1">
    <div class="justify-content-center">
        <img class="subscription-image" [src]="backgroundImage" />
    </div>
    <div class="justify-content-left">
        <h6 class="margin-top-0" [innerText]="subscription.name"></h6>
    </div>
</div>
<div class="row margin-top-1" *ngIf="subscription?.showDisclaimer">
    <div class="col-1"><span class="icon icon-warning icon-color font-size-xlarge"></span></div>
    <div class="col-11 font-size-xsmall" [innerHtml]="subscription?.disclaimerText">
    </div>
</div>
<div class="row margin-top-1" *ngIf="subscription?.requirePEPConnections && !team?.hasPEPConnections">
    <div class="col-1"><span class="icon icon-warning icon-color font-size-xlarge"></span></div>
    <div class="col-11">
        {{ 'LABEL_PEPCONNECTIONS_REQUIRED_MESSAGE_SHORT' | translate }}
    </div>
</div>
