<ng-container *ngIf="{
    loading: loading$ | async,
    error: error$ | async,
    posts: posts$ | async,
    hasMoreResults: hasMoreResults$ | async,
    teamMember: teamMember$ | async
    } as feedState">
    <app-collection>
        <app-collection-item>
            <app-collection-item-header>
                {{ 'TITLE_ACTIVITYFEED' | translate }}
            </app-collection-item-header>
        </app-collection-item>
        <app-collection-item class="margin-bottom-half" *ngIf="!loadFeed">
            <button [disabled]="feedState.loading"
                    id="activity-show-feed"
                    class="button button--primary"
                    type="button"
                    (click)="showFeed()">
                {{'ACTIVITY_FEED_SHOW' | translate}}
            </button>
        </app-collection-item>
        <app-collection-item class="margin-bottom-half" *ngIf="loadFeed && feedState.teamMember.allowActivityPost">
            <app-new-user-post-form></app-new-user-post-form>
        </app-collection-item>
        <app-collection-item class="margin-bottom-half" *ngIf="loadFeed && !feedState.teamMember.allowActivityPost">
            <p *ngIf="!feedState.teamMember.team.hasOwners">{{'TEXT_FEED_NOOWNER' | translate}}</p>
            <p *ngIf="feedState.teamMember.team.hasOwners">{{'TEXT_FEED_COMMENTING_DISABLED' | translate}}</p>
        </app-collection-item>
        <app-collection-item class="margin-bottom-half" *ngFor="let post of feedState.posts; index as i; trackBy: feedTrackBy">
            <app-activity-post *ngIf="!post.userPost" [post]="post" (onSuccessAction)="refreshPage()"></app-activity-post>
            <app-user-post *ngIf="post.userPost"
                           [post]="post"
                           [isActivityPage]="false"
                           (onPostModify)="refreshPage()"
                           (onUserPostComment)="applyComment($event)"
                           (onUserPostDelete)="deleteComment($event)"></app-user-post>
        </app-collection-item>
    </app-collection>
    <app-action-buttons>
        <button *ngIf="loadFeed && !feedState.loading && feedState.hasMoreResults"
                id="activity-load-more"
                class="button button--secondary"
                type="button"
                (click)="loadMore()">
            {{'BUTTON_LOADMORE' | translate}}
        </button>
        <app-spinner *ngIf="feedState.loading"></app-spinner>
        <div *ngIf="feedState.error" id="error-panel">
            <app-alert indicator="error" [accent-border]="true">
                {{ feedState.error | translate }}
            </app-alert>
            <br />
        </div>
    </app-action-buttons>
</ng-container>
