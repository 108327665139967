import { Component, OnInit, inject } from '@angular/core';
import { UserPost } from '@pepconnect/shared/feed/models/user-post.model';
import { FeedModalService } from '@pepconnect/shared/feed/services/feed-modal.service';

@Component({
  selector: 'app-user-post-image-viewer',
  templateUrl: './user-post-image-viewer.component.html',
  styleUrls: ['./user-post-image-viewer.component.scss']
})
export class UserPostImageViewerComponent implements OnInit {
  public static modalId = 'img-viewer';
  private modalsService = inject(FeedModalService);

  post: UserPost;

    ngOnInit(): void {
      this.post = this.modalsService.getModalData(UserPostImageViewerComponent.modalId);
    }
}
