import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LinkRel } from '@pepconnect/enums/link-rel.enum';
import { Team } from '@pepconnect/features/teams/models/team.model';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { Link } from '@pepconnect/models/general/link.model';
import { User } from '@pepconnect/models/user/user.model';
import { BaseFeedComponent } from '@pepconnect/shared/feed/base/base-feed.component';
import { ActivityFeedStore } from '@pepconnect/shared/feed/components/activity-feed/activity-feed.store';
import { TeamFeedStore } from '@pepconnect/shared/feed/components/team-feed/team-feed.store';
import { UserCommentComponent } from '@pepconnect/shared/feed/components/user-post/user-comment/user-comment.component';
import { feedConstants } from '@pepconnect/shared/feed/constants/feed-constants';
import { ActivityPost } from '@pepconnect/shared/feed/models/activity-post.model';
import { UserPost } from '@pepconnect/shared/feed/models/user-post.model';
import { FeedService } from '@pepconnect/shared/feed/services/feed.service';
import { AppState } from '@pepconnect/state/index';
import { of, takeUntil } from 'rxjs';
import { UserPostEvent } from '../../models/user-post-event.model';

@Component({
  selector: 'app-user-post',
  templateUrl: './user-post.component.html',
  styleUrls: ['./user-post.component.scss'],
  providers: [provideComponentStore(ActivityFeedStore)]
})
export class UserPostComponent extends BaseFeedComponent implements OnInit {
  @Input() isActivityPage: boolean;
  @Input() post: ActivityPost;
  @Output() onUserPostComment = new EventEmitter<UserPostEvent>();
  @Output() onUserPostDelete = new EventEmitter<string>();
  @Output() onPostModify = new EventEmitter<boolean>();

  readonly teamMember$ = this.teamStore.$teamMember;
  readonly maxCommentLength = +feedConstants.maxCommentLength;
  loggedInUser: User;
  team: Team;

  constructor(protected store: Store<AppState>,
    protected teamFeedStore: TeamFeedStore,
    private teamStore: TeamStore,
    private feedService: FeedService,
    private translate: TranslateService) { super(store, teamFeedStore); }

  ngOnInit(): void {
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe(authState => {
      this.loggedInUser = authState.user;
    });

    this.team = this.post?.team;
  }

  get postContent() {
    return `${this.post?.userPost?.user?.firstname} ${this.post?.userPost?.user?.lastname} in  ${this.post?.team?.name}`;
  }

  fileAction(commentComponent: UserCommentComponent, comment: UserPost, action: Link, $event: Event, el: HTMLElement) {
    (el as any)._onClose();
    $event.stopPropagation();
    $event.preventDefault();

    switch (action.rel) {
      case LinkRel.editUserPost:
        commentComponent.toggleEdit();
        break;
      case LinkRel.deleteUserPost:
        const confirmMessage = this.translate.instant('ACTIVITY_DELETE_POST_CONFIRM');
        if (confirm(confirmMessage)) {
          this.feedService.deleteUserPost(comment.teamID, comment.userPostID).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.onUserPostDelete.emit(comment.userPostID);
            },
            error: (err) => {
              return of(null);
            }
          });
        }
        break;
      case LinkRel.flagPost:
        const confirmFlagMessage = this.translate.instant('ACTIVITY_FLAG_POST_CONFIRM');
        if (confirm(confirmFlagMessage)) {
          this.feedService.flagUserPost(comment).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.onPostModify.emit(true);
            },
            error: (err) => {
              return of(null);
            }
          });
        }
        break;
      case LinkRel.unFlagPost:
        const confirmUnFlagMessage = this.translate.instant('ACTIVITY_UNFLAG_POST_CONFIRM');
        if (confirm(confirmUnFlagMessage)) {
          this.feedService.unflagUserPost(comment).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
            next: () => {
              this.onPostModify.emit(true);
            },
            error: (err) => {
              return of(null);
            }
          });
        }
        break;
    }

  }

  hideCommentLabel(count: number, isReply: boolean): string {
    if (isReply) {
      return '------- ' + this.translate.instant('TEXT_FEED_REPLIES_HIDE').replace('@@COUNT@@', count);
    } else {
      return this.translate.instant('TEXT_FEED_COMMENTS_HIDE').replace('@@COUNT@@', count);
    }
  }

  showCommentLabel(count: number, isReply: boolean): string {
    if (isReply) {
      return '------- ' + this.translate.instant('TEXT_FEED_REPLIES_VIEW').replace('@@COUNT@@', count);
    } else {
      return this.translate.instant('TEXT_FEED_COMMENTS_VIEW').replace('@@COUNT@@', count);
    }
  }

  // pass onto parent component
  relayEvent(event: UserPostEvent) {
    this.onUserPostComment.emit(event);
  }
}
