import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Team } from '@pepconnect/features/teams/models/team.model';
import { subscriptionDefaultImage } from '@pepconnect/functions/default-image';
import { Subscription } from '@pepconnect/models/subscription/subscription.model';

@Component({
  selector: 'app-subscription-post',
  templateUrl: './subscription-post.component.html',
  styleUrls: ['./subscription-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionPostComponent {
  @Input() subscription: Subscription;
  @Input() team: Team;

  get backgroundImage() {
    return this.subscription?.imagePath || subscriptionDefaultImage(this.subscription?.type?.name);
  }
}
