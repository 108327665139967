import { Component, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { AnalyticsService } from '@pepconnect/services/analytics.service';
import { NotificationService } from '@pepconnect/services/notification.service';
import { BaseFeedComponent } from '@pepconnect/shared/feed/base/base-feed.component';
import { feedConstants } from '@pepconnect/shared/feed/constants/feed-constants';
import { UserPost, defaultUserPostFactory } from '@pepconnect/shared/feed/models/user-post.model';
import { FeedService } from '@pepconnect/shared/feed/services/feed.service';
import { Subject, take, takeUntil, withLatestFrom } from 'rxjs';
import { TeamFeedStore } from '../team-feed.store';
import { AppState } from '@pepconnect/state/index';
import { TeamMember } from '@pepconnect/features/teams/models/team-member.model';

type AddCommentForm = Required<{
  postText: FormControl<string>
}>

@Component({
  selector: 'app-new-user-post-form',
  templateUrl: './new-user-post-form.component.html',
  styleUrls: ['./new-user-post-form.component.scss']
})
export class NewUserPostFormComponent extends BaseFeedComponent implements OnInit {
  readonly maxCommentLength = +feedConstants.maxCommentLength;
  addPostForm: FormGroup<AddCommentForm>;
  disablePostButton: boolean = false;

  private readonly teamStore = inject(TeamStore);
  private readonly fb = inject(FormBuilder);
  private readonly notificationService = inject(NotificationService);
  private readonly feedService = inject(FeedService);
  private readonly analyticsService = inject(AnalyticsService);
  readonly translateService = inject(TranslateService);

  reset$ = new Subject<void>();

  readonly teamMember$ = this.teamStore.$teamMember;
  pendingFile: File;
  teamMember: TeamMember;
  showUploader = false;
  fileUploadError: boolean = false;
  loading = false;

  constructor(protected store: Store<AppState>, protected teamFeedStore: TeamFeedStore) {
    super(store, teamFeedStore);
  }

  ngOnInit(): void {
    this.setupForm();
    this.setMember();
  }

  setMember() {
    this.teamMember$.pipe(takeUntil(this.ngUnsubscribe), withLatestFrom(this.checkFeatureFlag('pep-group-owner-upload-media-posts')))
      .subscribe(([teamMember, flag]) => {
        this.teamMember = teamMember;
        this.showUploader = teamMember.isAdmin && flag;
      });
  }

  get f() {
    return this.addPostForm?.controls;
  }

  setupForm() {
    this.addPostForm = this.fb.nonNullable.group({
      postText: this.fb.control('', [Validators.maxLength(this.maxCommentLength)])
    });
  }

  mediaSelected($event) {
    this.pendingFile = $event[0];
    this.fileUploadError = false;
  }

  onFileUploadError($event) {
    this.fileUploadError = $event;
  }

  removeAttachment() {
    this.pendingFile = null;
    this.fileUploadError = false;
  }

  // step 1 - check form is valid and data is ok
  onSubmit() {
    if (!this.addPostForm || this.addPostForm.invalid || this.fileUploadError) {
      return;
    }
    this.disablePostButton = true;
    if (this.addPostForm.value.postText !== '' || this.pendingFile && !this.fileUploadError) {
      this.createPostRequest();
    } else {
      this.notificationService.showError(this.translateService.instant('REQUIRED_FIELD_COMMENT'));
      this.disablePostButton = false;
    }
  }

  // step 2 - create the post object and upload the optional media
  createPostRequest() {
    let newUserPost: UserPost = defaultUserPostFactory();
    newUserPost.message = this.addPostForm.value.postText;

    if (this.teamMember && this.pendingFile) {
      this.loading = true;
      this.feedService.uploadPostMedia(this.teamMember.team.teamID, this.pendingFile).pipe(take(1))
        .subscribe({
          next: ({ Message }) => {
            newUserPost.mediaUrl = Message; // add the media url to the post
            newUserPost.mediaSize = this.pendingFile.size; // add the media size to the post
            this.pendingFile = null; // reset the pending file
            this.sendPostRequest(newUserPost);
          }, error: () => {
            // todo: handle error
            this.disablePostButton = false;
          }
        });
    } else {
      this.sendPostRequest(newUserPost);
    }
  }

  // send it via the feed service
  sendPostRequest(newUserPost: UserPost) {
    this.feedService.addUserPost(newUserPost, this.teamMember.team.teamID).pipe(take(1))
      .subscribe((activityPost) => {
        this.analyticsService.trackGroup({ action: 'group.post', data: { group: { name: this.teamMember.team.name } } });
        this.addPostForm.get('postText').setValue('');
        this.teamFeedStore.addPosts([activityPost]);
        this.disablePostButton = false;
        this.loading = false;
        this.reset$.next();
      });
  }


}
