import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UserLearningPlan } from '@pepconnect/models/plan/user-learning-plan.model';

@Component({
  selector: 'app-plan-post',
  templateUrl: './plan-post.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlanPostComponent {
  @Input() plan: UserLearningPlan;
}
