import { Component, EventEmitter, Input, Output } from '@angular/core';
import { constants } from '@pepconnect/constants';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-post-file-uploader',
  templateUrl: './post-file-uploader.component.html'
})

export class PostFileUploaderComponent {
  attachmentsAccept = ['.gif', '.jpg', '.jpeg', '.png', '.mp4', '.mov', '.webm', '.mpg', '.avi', '.wmv', '.flv'];
  sizeLimit = constants.DEFAULT_USER_POST_FILE_SIZE_LIMIT_MB;

  @Input() validationClass: string;
  @Input() label: string;
  @Input() reset$: Subject<void>;
  @Output() selected = new EventEmitter<File>();
  @Output() fileError = new EventEmitter<boolean>();

  onSelection($event) {
    this.selected.emit($event);
  }

  onFileError($event) {
    this.fileError.emit($event);
  }

}
