<ng-container *ngIf="!loading; else loader">
    <app-form-full-width (onSubmit)="onSubmit()" *ngIf="f" [formGroup]="addPostForm" [inline]="true">
    <div class="col-md-10">
        <app-form-group>
            <app-textfield-group [skip-invalid]="false">
                <label class="textfield__label" for="add-post">{{ 'ACTIVITY_FEED_POST_PLACEHOLDER' | translate }}</label>
                <input type="text"
                       class="textfield__input"
                       id="add-post"
                       name="add-post-name"
                       formControlName="postText">
                <app-validation-message [control]="f.postText" [showOnSubmitted]="false" error="maxLength" message="{{('ACTIVITY_COMMENTS_MAX_LENGTH' | translate).replace('@@MAXLENGTH@@',maxCommentLength)}}"></app-validation-message>
            </app-textfield-group>
            </app-form-group>
        <app-post-file-uploader [reset]="reset$" (selected)="mediaSelected($event)" (fileError)="onFileUploadError($event)" label="BUTTON_MEDIA_UPLOAD" validationClass="textfield__hint_red" *ngIf="showUploader && !pendingFile"></app-post-file-uploader>
            <div *ngIf="pendingFile" class="display-flex justify-content-between">
                <span>{{ pendingFile.name }}</span>
                <span class="icon-close" [attr.aria-label]="'BUTTON_REMOVE' | translate"
                    (click)="removeAttachment()"></span>
            </div>
        </div>
        <div class="col-md-2 add-post-button">
            <app-form-buttons-inline>
                <button id="add-post-post" class="button button--primary" type="submit" [disabled]="disablePostButton">{{'BUTTON_POSTCOMMENT'| translate}}</button>
            </app-form-buttons-inline>
        </div>
    </app-form-full-width>
</ng-container>
<ng-template #loader>
    <app-spinner [centered]="true"></app-spinner>
</ng-template>
