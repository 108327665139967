<div class="comment" [class.is-flagged]="commentPost?.isFlagged">
    <app-user-avatar [userAvatar]="commenter" size="small"></app-user-avatar>
    <div class="comment-content">
        <small *ngIf="!isNewComment">
            <div class="comment-user">
                <p class="margin-zero"><strong>{{commenter.firstname }} {{commenter.lastname}}</strong></p>
                <p *ngIf="isDepthLevelOne" class="margin-zero">&nbsp;in&nbsp;</p>
                <p class="team-name-label" *ngIf="isDepthLevelOne" class="margin-zero" [class.team-link]="isActivityPage" (click)="goToTeam()">
                    <strong>{{team?.name}}</strong>
                </p>
            </div>
        </small>
        <small *ngIf="isDepthLevelOne">
            {{commentPost?.modifiedDate | date: "longDate"}}
        </small>
        <small *ngIf="!isDepthLevelOne">
            {{commentPost?.modifiedDate | date: "shortDate"}}
            {{commentPost?.modifiedDate | date: "shortTime"}}
        </small>
        <div class="margin-bottom-single" *ngIf="!editable || !(showEdit || isNewComment)">
            <small>{{commentPost?.message}}</small>
        </div>
        <div class="margin-bottom-single">
            <app-user-post-media [post]="commentPost"></app-user-post-media>
        </div>
        <form [formGroup]="editCommentForm" class="edit-comment-form" *ngIf="(showEdit || isNewComment) && editable"
            (ngSubmit)="onSubmit()">
            <div class="textfield js-textfield textfield-with-prefix">
                <label *ngIf="placeholder" class="textfield__label" for="edit-post">
                    {{placeholder}}
                </label>
                <input type="text" class="textfield__input" id="edit-post" name="edit-post-name"
                    formControlName="editCommentText">
                <small class="color-red-dark textfield__hint"
                    *ngIf="f.editCommentText.touched && f.editCommentText.hasError('required')">
                    {{ 'REQUIRED_FIELD' | translate }}
                </small>
            </div>
            <button class="button button--primary" type="submit">
                {{ saveButtonText | translate}}</button>
            <button *ngIf="!isNewComment" class="button" type="button" (click)="cancelForm()">
                {{ 'BUTTON_CANCEL'| translate}}</button>
        </form>
    </div>
</div>
