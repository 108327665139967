import { Injectable } from "@angular/core";
import { ComponentStore } from "@ngrx/component-store";
import { ActivityPost } from "@pepconnect/shared/feed/models/activity-post.model";
import { UserPostEvent } from "../models/user-post-event.model";
import { UserPost } from "../models/user-post.model";
import { map } from "rxjs";

/** Base Feed Component Store.
 *
 *  Base component store class for activity-feed-store and team-feed-store
 * 
 *  Acts as a mini-store that can be shared as a singleton between component trees
 *  trees to avoid having to pass in a large amount of individual observables as inputs
 *  Usage:
 *  1) To create a new scoped store that can be shared with a component tree - Add a providers[] array to the top-level component metadata with `[provideComponentStore(FeedStore)]`.
 *     This will create the singleton.
 *  2) Inject private feedStore: FeedStore to the constructor (or Inject() function if you live on the wildside) in components that need to access that state.
 *     DO NOT ADD THE PROVIDER to the component metadata for children that should inherit the warm-instance of the store - you only need to inject the store in the constructor
 * */

/**
 * https://ngrx.io/guide/component-store/usage
 * https://www.youtube.com/watch?v=r0Rzt4lQ0T0
 * https://medium.com/ngconf/using-ngrx-component-store-introduction-7787ce250edc
 */

export interface FeedState {
  posts: ActivityPost[], // current list of activity posts
  pageNumber: number,
  hasMoreResults: boolean,
  loading: boolean, // loading indicator
  error: string, // error object
}

export const defaultFeedState: FeedState =
{
  posts: [], // current list of activity posts
  pageNumber: 0,
  hasMoreResults: true,
  loading: false,
  error: null,
}

@Injectable()
export class BaseFeedStore extends ComponentStore<FeedState> {
  readonly PAGE_SIZE: number = 15;

  constructor(
  ) {
    super(defaultFeedState);
  }

  // *********** Selectors *********** //
  readonly posts$ = this.select(({ posts }) => posts);
  readonly loading$ = this.select(({ loading }) => loading);
  readonly error$ = this.select(({ error }) => error);
  readonly hasMoreResults$ = this.select(({ hasMoreResults }) => hasMoreResults);
  readonly pageNumber$ = this.select(({ pageNumber }) => pageNumber);

  // *********** Updaters *********** //
  readonly setPosts = this.updater((state, posts: ActivityPost[]): FeedState => ({
    ...state,
    posts
  }));

  readonly addPosts = this.updater((state, newPosts: ActivityPost[]): FeedState => {
    return {
      ...state,
      posts: [...state.posts, ...newPosts]
    }
  });

  readonly setPageNumber = this.updater((state, pageNumber: number): FeedState => ({
    ...state,
    pageNumber
  }));

  readonly setHasMoreResults = this.updater((state, hasMoreResults: boolean): FeedState => ({
    ...state,
    hasMoreResults
  }));

  readonly setError = this.updater((state, error: string | null): FeedState => ({
    ...state,
    error
  }));

  readonly setLoading = this.updater((state, loading: boolean): FeedState => ({
    ...state,
    loading
  }));

  onApiError(err: any) {
    this.setLoading(false);
    this.setError(err?.Message ?? err ?? 'error');
  }


  readonly addComment = this.updater((state, userPostEvent: UserPostEvent): FeedState => ({
    ...state,
    posts: state.posts.map(activityPost => {
      // don't do anything if this isn't a user post
      if (!activityPost.userPost) {
        return activityPost;
      }

      return {
        ...activityPost,
        userPost: this.applyEventToUserPost(activityPost.userPost, userPostEvent)
      };
    })
  }));

  readonly deleteComment = this.updater((state, userPostId: string): FeedState => ({
    ...state,
    posts: state.posts.map(activityPost => {
      // don't do anything if this isn't a user post
      if (!activityPost.userPost) {
        return activityPost;
      }

      if (activityPost.userPost.userPostID === userPostId) {
        return null;
      }

      return {
        ...activityPost,
        userPost: this.deleteChildUserPost(activityPost.userPost, userPostId)
      };
    }).filter(s => !!s)
  }));

  // private function for recursion down the comment tree while applying the user post event
  private applyEventToUserPost(post: UserPost, userPostEvent: UserPostEvent): UserPost {
    // case 1: the post is the parent of a new comment
    if (post.userPostID === userPostEvent.parentUserPostId) {
      return {
        ...post,
        comments: (post.comments ?? []).concat(userPostEvent.userPost)
      };
    }

    return {
      ...post,
      comments: post.comments.map(comment => {
        // case 2: the post is an edit of one of the comments
        if (comment.userPostID === userPostEvent.userPost?.userPostID) {
          return userPostEvent.userPost;
        }

        // case 3: it's among the children
        return this.applyEventToUserPost(comment, userPostEvent);
      })
    };
  }

  // private function for recursion down the comment tree while deleting a user post
  private deleteChildUserPost(post: UserPost, userPostId: string): UserPost {
    return {
      ...post,
      comments: post.comments.map(comment => {
        // case 1: the post is the one being deleted
        if (comment.userPostID === userPostId) {
          return null;
        }

        // case 2: it's among the children
        return this.deleteChildUserPost(comment, userPostId);
      }).filter(s => !!s)
    };
  }

}
