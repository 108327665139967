import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Team } from '@pepconnect/features/teams/models/team.model';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { UserAvatar } from '@pepconnect/models/user/user-avatar.model';
import { User } from '@pepconnect/models/user/user.model';
import { AnalyticsService } from '@pepconnect/services/analytics.service';
import { BaseFeedComponent } from '@pepconnect/shared/feed/base/base-feed.component';
import { ActivityFeedStore } from '@pepconnect/shared/feed/components/activity-feed/activity-feed.store';
import { TeamFeedStore } from '@pepconnect/shared/feed/components/team-feed/team-feed.store';
import { feedConstants } from '@pepconnect/shared/feed/constants/feed-constants';
import { UserPostEvent } from '@pepconnect/shared/feed/models/user-post-event.model';
import { UserPost } from '@pepconnect/shared/feed/models/user-post.model';
import { FeedService } from '@pepconnect/shared/feed/services/feed.service';
import { AppState } from '@pepconnect/state/index';
import { takeUntil } from 'rxjs';

type EditCommentForm = {
  editCommentText: FormControl<string>
}

@Component({
  selector: 'app-user-comment',
  templateUrl: './user-comment.component.html',
  styleUrls: ['./user-comment.component.scss']
})

/** This component houses all the comments including parent post with child(replies)
 * and enable replies if allowed --> flag comes from as "allowComments"
 * from API  */
export class UserCommentComponent extends BaseFeedComponent implements OnInit {
  readonly teamMember$ = this.teamStore.$teamMember;
  readonly post$ = this.activityFeedStore.posts$;
  readonly maxCommentLength = +feedConstants.maxCommentLength;

  @Input() commentPost: UserPost;
  @Input() parentCommentPost: UserPost;
  @Input() placeholder: string;
  @Input() saveButtonText: string;
  @Input() isActivityPage: boolean;
  @Input() team: Team;
  @Input() isDepthLevelOne: boolean; // used for conditional labels on parent comment i.e. child comments are excluded

  @Output() onUserPostComment = new EventEmitter<UserPostEvent>();

  showEdit = false;
  editCommentForm: FormGroup<EditCommentForm>;
  commenter: UserAvatar;
  editable: boolean;
  loggedInUser: User;

  private fb = inject(FormBuilder);

  // constructor is full per sonar.  Use static inject instead for any new deps.
  constructor(
    protected store: Store<AppState>,
    protected teamFeedStore: TeamFeedStore,
    private teamStore: TeamStore,
    private feedService: FeedService,
    private activityFeedStore: ActivityFeedStore,
    private router: Router,
    private analyticsService: AnalyticsService) { super(store, teamFeedStore); }

  ngOnInit(): void {
    this.setupEditCommentForm();

    this.editable = false;
    this.$authState.pipe(takeUntil(this.ngUnsubscribe)).subscribe(authState => {
      this.loggedInUser = authState.user;
      if (this.commentPost) {
        this.editable = authState.user.id === this.commentPost.user?.id;
        this.commenter = {
          firstname: this.commentPost.user?.firstname,
          lastname: this.commentPost.user?.lastname,
          avatar: this.commentPost.user?.imageURL
        }
      } else {
        if (!this.parentCommentPost) {
          throw new Error("userPostID or commentPost is required");
        }

        this.editable = true;
        this.commenter = {
          firstname: authState.user?.firstname,
          lastname: authState.user?.lastname,
          avatar: authState.user?.imageURL
        }
      }

    });
  }

  get isNewComment() {
    return !this.commentPost;
  }

  toggleEdit() {
    if (!this.editable) {
      return;
    }

    this.showEdit = !this.showEdit;
  }

  setupEditCommentForm() {
    this.editCommentForm = this.fb.group({
      editCommentText: this.fb.control(this.commentPost?.message, [Validators.maxLength(this.maxCommentLength)])
    })
  }

  get f() {
    return this.editCommentForm?.controls;
  }

  onSubmit() {
    if (!this.editCommentForm || this.editCommentForm.invalid) {
      return;
    }

    const editCommentText = this.f.editCommentText.value;
    let inEditPost = Object.assign(this.commentPost ?? {}, { message: editCommentText }) as UserPost;

    if (this.commentPost) {
      this.feedService.editUserPost(inEditPost).pipe(takeUntil(this.ngUnsubscribe)).subscribe({
        next: (res: any) => {
          this.onUserPostComment.emit({
            userPost: inEditPost,
            parentUserPostId: null
          });
          this.commentPost.message = res as string;
          this.f.editCommentText.setValue(res as string);
          this.f.editCommentText.markAsPristine();
          this.f.editCommentText.markAsUntouched();
          this.showEdit = false;
        }
      });
    } else {
      this.feedService.postComment(this.parentCommentPost.teamID, this.parentCommentPost.userPostID, inEditPost)
        .pipe(takeUntil(this.ngUnsubscribe)).subscribe({
          next: (res) => {
            this.analyticsService.trackGroup({ action: 'group.comment', data: { group: { name: this.team.name } } });
            this.onUserPostComment.emit({
              userPost: res,
              parentUserPostId: this.parentCommentPost.userPostID
            });
            this.f.editCommentText.setValue('');
            this.f.editCommentText.markAsPristine();
            this.f.editCommentText.markAsUntouched();
            this.showEdit = false;
          }
        });
    }
  };

  cancelForm() {
    this.showEdit = false;
  }

  goToTeam() {
    if (!this.isActivityPage) {
      return;
    }

    // route to group details page
    this.router.navigate([this.loggedInUser.locale.locale, 'groups', this.team?.friendlyURL]);
  }

}
