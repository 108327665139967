import { Component, OnInit } from '@angular/core';
import { provideComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { constants } from '@pepconnect/constants/constants';
import { TeamStore } from '@pepconnect/features/teams/teams.store';
import { UserService } from '@pepconnect/services/user.service';
import { BaseFeedComponent } from '@pepconnect/shared/feed/base/base-feed.component';
import { ActivityFeedStore } from '@pepconnect/shared/feed/components/activity-feed/activity-feed.store';
import { TeamFeedStore } from '@pepconnect/shared/feed/components/team-feed/team-feed.store';
import { ActivityPost } from '@pepconnect/shared/feed/models/activity-post.model';
import { AppState } from '@pepconnect/state';
import { selectAuthState } from '@pepconnect/state/auth/auth.selector';
import { combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';
import { UserPostEvent } from '../../models/user-post-event.model';

/**
 *
 * This component is used to display the user's activity feed
 * 
 * */
@Component({
  selector: 'app-activity-feed',
  templateUrl: './activity-feed.component.html',
  styleUrls: ['./activity-feed.component.scss'],
  providers: [provideComponentStore(ActivityFeedStore), provideComponentStore(TeamFeedStore), provideComponentStore(TeamStore)]
})
export class ActivityFeedComponent extends BaseFeedComponent implements OnInit {
  readonly authState$ = this.store.select(selectAuthState);
  readonly posts$ = this.activityFeedStore.posts$;
  readonly hasMoreResults$ = this.activityFeedStore.hasMoreResults$;
  showEpt: boolean;
  loadFeed: boolean;

  constructor(protected store: Store<AppState>,
    protected activityFeedStore: ActivityFeedStore,
    private userService: UserService
  ) {
    super(store, activityFeedStore);
  }

  ngOnInit(): void {
    this.setFlags();
  }

  /**
   * Get launch darkly flags to decide whether to load feed and display EPT
   * */
  setFlags() {
    this.userService.checkFeatureFlag(constants.FLAG_EPT)
      .pipe(take(1))
      .subscribe(flag => {
        this.showEpt = !!flag;
      });

    this.userService.checkFeatureFlag(constants.FLAG_LOAD_FEED)
      .pipe(take(1))
      .subscribe(flag => {
        this.loadFeed = !!flag;
        if (this.loadFeed) {
          this.setupFeed();
        }
      });
  }

  /**
   * Clears out any existing feed in the store, load current feed
   * */
  setupFeed() {
    this.authState$.pipe(take(1))
      .subscribe((authState) => {
        this.activityFeedStore.setError(null);

        // if user is not logged in or impersonating, throw message
        if (!authState.isInitialized || !authState.isLoggedIn || authState.isImpersonating) {
          this.activityFeedStore.setError('You must be logged in and not impersonating to view your activity feed.');
        }
        else { // user is logged in and not impersonating, setup the store
          this.activityFeedStore.initializeUserFeed(authState.user.id);
        }
      });
  }

  /**
   * Called when user selects "Load More"
   * */
  loadMore() {
    this.authState$.pipe(take(1))
      .subscribe((authState) => {
        // if user is not logged in or impersonating, throw message
        if (!authState.isLoggedIn || authState.isImpersonating) {
          this.activityFeedStore.setError('You must be logged in and not impersonating to view your activity feed.');
        }
        else { // user is logged in and not impersonating, setup the store
          this.activityFeedStore.loadNextUserFeedPage(authState.user.id);
        }
      });
  }

  feedTrackBy(index, post: ActivityPost) {
    return post.id;
  }

  showFeed() {
    this.loadFeed = true;
    this.setupFeed();
  }

  refreshPage() {
    combineLatest([this.authState$, this.posts$]).pipe(take(1))
      .subscribe(([authState, posts]) => {
        this.activityFeedStore.setError(null);

        // if user is not logged in or impersonating, throw message
        if (!authState.isInitialized || !authState.isLoggedIn || authState.isImpersonating) {
          this.activityFeedStore.setError('You must be logged in and not impersonating to view your activity feed.');
        }
        else { // user is logged in and not impersonating, setup the store
          this.activityFeedStore.reloadFeedRange({ userId: authState.user.id, pageSize: posts.length });
        }
      });
  }
  
  applyComment(event: UserPostEvent)
  {
    this.activityFeedStore.addComment(event);
  }

  deleteComment(userPostId: string)
  {
    this.activityFeedStore.deleteComment(userPostId);
  }
}
