import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { defaultImage } from '@pepconnect/functions/default-image';
import { V1LearningObject } from '@pepconnect/models/content/learning-object-v1.model';

@Component({
  selector: 'app-content-post',
  templateUrl: './content-post.component.html',
  styleUrls: ['./content-post.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentPostComponent {
  @Input() content: V1LearningObject;
  
  get backgroundImage() {
    return this.content?.imagePath || defaultImage(this.content?.type);
  }

}
